//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fetch from 'node-fetch'
import { abortableFetch } from 'abortcontroller-polyfill/dist/cjs-ponyfill'
import Header from '@/components/Header'
import MobileMenu from '@/components/MobileMenu'
global.fetch = abortableFetch(fetch).fetch
export default {
  components: {
    Header,
    MobileMenu,
  },
  computed: {
    routerViewKey() {
      if (this.$route.matched.length <= 1) {
        return this.$route.fullPath.split(/[?|#]/)[0]
      } else {
        return false
      }
    },
  },
}
