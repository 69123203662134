//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import VScrollLock from 'v-scroll-lock'
// import { Expo } from 'gsap'
// import { mainmenuQuery } from '~/graphql/queries/mainmenu'
import Logo from '@/components/Logo'
Vue.use(VScrollLock)
export default {
  components: {
    Logo,
  },
  props: {
    intro: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
      top: true,
      hide: false,
    }
  },

  computed: {
    menu() {
      return this.$store.state.menu
    },
  },

  // watch: {
  //   $route(to, from) {
  //     this.handleScroll()
  //   },
  // },

  methods: {
    toggle() {
      this.$store.dispatch('toggleMenu')
    },
  },
}
