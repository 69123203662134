var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{class:{
    top: _vm.top,
    hide: _vm.hide,
    'on-intro': _vm.intro,
    'menu-open': _vm.$store.state.showMenu,
  }},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"inner"},[_c('Logo',{ref:"logo",attrs:{"minimize":!_vm.top}}),_vm._v(" "),_c('div',{staticClass:"toggle",on:{"click":_vm.toggle}},[_vm._v("\n        "+_vm._s(_vm.$store.state.showMenu ? 'Close' : 'Menu')+"\n      ")]),_vm._v(" "),(_vm.menu)?_c('ul',{staticClass:"main-menu"},_vm._l((_vm.menu.data.attributes.items.data),function(menuitem,index){return _c('li',{key:index},[(menuitem.attributes.url)?_c('a',{attrs:{"href":menuitem.attributes.url,"target":"_self"}},[_c('span'),_vm._v(_vm._s(menuitem.attributes.title))]):(menuitem.attributes.page_relation.data)?_c('nuxt-link',{attrs:{"to":'/' + menuitem.attributes.page_relation.data.attributes.slug}},[_c('span'),_vm._v(_vm._s(menuitem.attributes.title))]):_c('nuxt-link',{attrs:{"to":'/'}},[_c('span'),_vm._v(_vm._s(menuitem.attributes.title))]),_vm._v(" "),(menuitem.attributes.children.data.length > 0)?_c('ul',{staticClass:"sub-menu"},_vm._l((menuitem.attributes.children
                .data),function(submenuitem,index){return _c('li',{key:index},[(submenuitem.attributes.url)?_c('a',{attrs:{"href":submenuitem.attributes.url,"target":"_self"}},[_c('span'),_vm._v(_vm._s(submenuitem.attributes.title))]):(submenuitem.attributes.page_relation.data)?_c('nuxt-link',{attrs:{"to":'/' +
                  menuitem.attributes.page_relation.data.attributes.slug +
                  '/' +
                  submenuitem.attributes.page_relation.data.attributes.slug}},[_c('span'),_vm._v(_vm._s(submenuitem.attributes.title))]):_c('nuxt-link',{attrs:{"to":'/'}},[_c('span'),_vm._v(_vm._s(submenuitem.attributes.title))])],1)}),0):_vm._e()],1)}),0):_vm._e()],1),_vm._v(" "),_c('div',{ref:"width"})])])}
var staticRenderFns = []

export { render, staticRenderFns }