//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    minimize: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    type() {
      return this.$route
    },
  },
  methods: {
    onClick(event) {
      this.$store.dispatch('hideMenu')
    },
  },
}
