import gql from "graphql-tag";

export const projectsQuery = gql`
  query Projects {
    projects(sort: "date:DESC", pagination: { limit: 100 }) {
      data {
      id
        attributes {
          slug
          title
          headline
          type {
            data {
              id
            }
          }
          image {
            data {
              attributes {
                width
                height
                caption
                mime
                url
                hash
                ext
              }
            }
          }
          video
          status {
            data {
              attributes {
                title
              }
            }
          }
        }
      }
    }
  }
`