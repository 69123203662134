import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import introspectionResult from '~/fragmentTypes.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: introspectionResult,
  });
export default ({ req, app }) => {
    return {
      httpEndpoint: app.$config.BACKEND_URL,
      cache: new InMemoryCache({ fragmentMatcher })
    }
}